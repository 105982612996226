<template>
    <div id="inner" class="extra-space">
        <div class="row">
            <div class="col-12">
                <h1>
                    Urgenten
                </h1>

            </div>
        </div>
        <div class="row">
            <div class="col-12 spacer">
                &nbsp;
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <form class="form-inline">
                    <div class="form-group mb-2">
                        <label for="filter_label" class="sr-only">Label</label>
                        <select
                                id="filter_label"
                                class="form-control filter"
                                v-model="filter.label"
                                v-on:change="doFilter"
                        >
                            <option value="">Alle Labels</option>
                            <option
                                    v-for="item in labels"
                                    :key="item.id"
                                    v-bind:value="item.id"
                            >
                                {{item.name}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group mx-sm-3 mb-2">
                        <label for="filter_date" class="sr-only">Datum urgentie toegewezen</label>
                        <input type="date" v-model="filter.regdate" class="form-control" placeholder="Datum urgentie toegewezen" v-on:change="doFilter">
                    </div>
                    <div class="form-group mx-sm-3 mb-2">
                        <label for="filter_status" class="sr-only">Datum urgentie toegewezen</label>
                        <select
                                id="filter_status"
                                class="form-control filter"
                                v-model="filter.status"
                                v-on:change="doFilter"
                        >
                            <option value="">Alle Statussen</option>
                            <option value="Open">Open</option>
                            <option value="In Behandeling">In Behandeling</option>
                            <option value="Afgehandeld">Afgehandeld</option>
                        </select>
                    </div>
                    <div class="form-group mx-sm-3 mb-2">
                        <label for="filter_keyword" class="sr-only">Adres</label>
                        <input type="text" v-model="filter.keyword" class="form-control" placeholder="Sleutelwoord" v-on:keyup="doFilter">
                    </div>

                    <div class="form-group mx-sm-3 mb-2">
                        <label for="filter_lastname" class="sr-only">Lastname</label>
                        <input type="text" v-model="filter.lastname" class="form-control" placeholder="Achternaam" v-on:keyup="doFilter">
                    </div>

                    <div class="form-group mx-sm-3 mb-2">
                        <label for="filter_rooms" class="sr-only">Rooms</label>
                        <input type="text" v-model="filter.rooms" class="form-control" placeholder="Minimale kamers" v-on:keyup="doFilter">
                    </div>

                    <div class="form-group mx-sm-3 mb-2">
                        <label for="filter_ageFrom" class="sr-only">AgeFrom</label>
                        <input
                        type="text"
                        v-model="filter.ageFrom"
                        class="form-control"
                        placeholder="Leeftijd vanaf"
                        v-on:input="validateNumeric('ageFrom')"
                        />
                    </div>

                    <div class="form-group mx-sm-3 mb-2">
                        <label for="filter_ageTo" class="sr-only">AgeTo</label>
                        <input
                        type="text"
                        v-model="filter.ageTo"
                        class="form-control"
                        placeholder="Leeftijd tot"
                        v-on:input="validateNumeric('ageTo')"
                        :readonly="isAgeToReadonly"
                        :class="{'greyed-out': isAgeToReadonly}"
                        />
                    </div>
                </form>
                        <!-- Styles to grey out the input field -->
                        <style scoped>
                        .greyed-out {
                            background-color: #dcdcdc;
                        }
                        </style>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <table class="table">
                    <thead>
                        <tr>
                            <th>
                                Dossier
                            </th>
                            <th>
                                Label
                            </th>
                            <th>
                                Leeftijd
                            </th>
                            <th>
                                Huishoudgrootte
                            </th>
                            <th>
                                Minimale kamers
                            </th>
                            <th>
                                Datum urgentie toegekend
                            </th>
                            <th>
                                Status
                            </th>
                            <th class="text-center">
                                <a v-if="!$store.state.authentication.status.corporation" href="/urgenten/new">
                                    <img src="../assets/icons/plus.svg">
                                </a>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="item in items">
                            <tr
                                    v-on:click="toggleVisibility(item.id)"
                                    :key="item.id+'_parent'"
                                    v-if="!$store.state.authentication.status.corporation || item.finished === 0"
                                    v-bind:class="{
                                        'alert-info': item.mega,
                                        'alert-danger extra': !item.mega && item.daysSinceStart > 180,
                                        'alert-danger': !item.mega && item.daysSinceStart > 150 && item.daysSinceStart <= 180,
                                        'alert-warning': !item.mega && item.daysSinceStart > 90 && item.daysSinceStart <= 150,
                                    }"
                            >

                                <!---
                                <td>
                                    {{item.dossier}}
                                </td>
                                --->
                                <!-- Dossier column styled as clickable (Papy) -->
                                <td
                                    @click="$bvModal.show(`contact_modal_${item.id}`)"
                                    style="cursor: pointer; text-decoration: underline;"
                                >
                                    {{ item.dossier }}
                                    <!-- Modal specific to the current item to display contact information (Papy)-->
                                   <b-modal
                                                :id="`contact_modal_${item.id}`"
                                                :title="`Contactinformatie voor dossier:  ${item.dossier}`"
                                                :header-text-variant="'dark'">
                                            <div>
                                                <p><strong>Contact persoon:</strong> {{ item.contactperson || 'Niet opgegeven' }}</p>
                                                <p><strong>Contact telefoon:</strong> {{ item.contactphone || 'Niet opgegeven' }}</p>

                                                <hr style="border: 1px solid #000; margin: 20px 0;"/>

                                                <p><strong>Urgent:</strong> {{ item.lastname || 'Niet opgegeven' }}</p>
                                                <p><strong>Geboortedatum:</strong> {{ formatDate(item.birthdate) }}</p>
                                            </div>
                                    </b-modal>
                                </td>

                                <td
                                        @click="$bvModal.show(`contact_modal_${item.id}`)"
                                        style="cursor: pointer; text-decoration: underline;"
                                >
                                    {{item.label}}
                                </td>
                                <td>
                                    {{item.age}}
                                </td>
                                <td>
                                    {{item.adults + item.children}}
                                </td>
                                <td>
                                    {{item.rooms}}
                                </td>
                                <td>
                                    {{item.regdate}}
                                </td>
                                <td>
                                    {{item.status}}
                                </td>
                                <td class="text-center">
                                    <a v-if="!$store.state.authentication.status.corporation" :href="'/urgenten/'+item.id">
                                        <img class="icon-small" src="../assets/icons/chevron-right.svg">
                                    </a>
                                    <span v-if="$store.state.authentication.status.corporation && item.inClaim === 0 && item.finished === 0">
                                        <img v-bind:class="{'icon-small': true, 'chevron': true, 'up': isVisible.includes(item.id)}" src="../assets/icons/chevron-down.svg">
                                    </span>
                                </td>
                            </tr>
                            <tr :key="item.id+'_child'" v-if="isVisible.includes(item.id) && $store.state.authentication.status.corporation && item.finished === 0">
                                <td colspan="100%" class="urgent-foldout" v-if="item.inClaim === 0 && item.finished === 0">
                                    <div class="row">
                                        <div class="col-12">
                                            <h3>
                                                {{item.dossier}}
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="row foldout-naw">
                                        <div class="col-4">
                                            <ul class="list-unstyled">
                                                <li>
                                                    Leeftijd: {{item.age}}
                                                </li>
                                                <li>
                                                    Volwassenen: {{item.adults}}
                                                </li>
                                                <li>
                                                    Kinderen: {{item.children}}
                                                </li>
                                                <li>
                                                    Afkomstig van: {{item.notes}}
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-4">
                                            <ul class="list-unstyled">
                                                <li>
                                                    Inkomen: {{item.income}}
                                                </li>
                                                <li>
                                                    Woonlasten (max): {{item.fitting.max}}
                                                </li>
                                                <li>
                                                    Wijken Niet: {{item.neighborhood}}
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-4">
                                            <ul class="list-unstyled">
                                                <li>
                                                    Minimaal aantal kamers: {{item.rooms}}
                                                </li>
                                                <li>
                                                    Vereisten:
                                                    <ul>
                                                        <li v-for="subitem in item.profilefields" :key="subitem.id">
                                                            {{subitem.name}}
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row foldout-houses" v-if="item.wmcorp">
                                        <div class="col-12">
                                            <h2>
                                               Beschikbare Woningen
                                            </h2>
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            &nbsp;
                                                        </th>
                                                        <th>
                                                            Adres
                                                        </th>
                                                        <th>
                                                            Wijk
                                                        </th>
                                                        <th>
                                                            Doelgroep
                                                        </th>
                                                        <th>
                                                            Type
                                                        </th>
                                                        <th>
                                                            Huur
                                                        </th>
                                                        <th>
                                                            Advertentiedatum
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="item.advert.fitting.length">
                                                        <td colspan="100%">
                                                            <strong>
                                                                Voldoen wel aan zoekprofiel:
                                                            </strong>
                                                        </td>
                                                    </tr>
                                                    <tr v-for="advert in item.advert.fitting" :key="advert.id">
                                                        <td>
                                                            <input type="radio" v-bind:value="advert.id+'|'+advert.address" v-model="claim.combined">
                                                        </td>
                                                        <td>
                                                            {{advert.address}}
                                                        </td>
                                                        <td>
                                                            {{advert.neighborhood}}
                                                        </td>
                                                        <td>
                                                          {{advert.target.length > 0 ? advert.target : 'Algemeen'}}
                                                        </td>
                                                        <td>
                                                            {{advert.type}}
                                                        </td>
                                                        <td>
                                                            &euro; {{advert.rent.total}}
                                                        </td>
                                                        <td>
                                                            {{advert.period}}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="item.advert.rest.length">
                                                        <td colspan="100%">
                                                            <strong>
                                                                Voldoen niet aan zoekprofiel:
                                                            </strong>
                                                        </td>
                                                    </tr>
                                                    <tr v-for="advert in item.advert.rest" :key="advert.id">
                                                        <td>
                                                            <input type="radio" v-bind:value="advert.id+'|'+advert.address" v-model="claim.combined">
                                                        </td>
                                                        <td>
                                                            {{advert.address}}
                                                        </td>
                                                        <td>
                                                            {{advert.neighborhood}}
                                                        </td>
                                                        <td>
                                                          {{advert.target.length > 0 ? advert.target : 'Algemeen'}}
                                                        </td>
                                                        <td>
                                                            {{advert.type}}
                                                        </td>
                                                        <td>
                                                            &euro; {{advert.rent.total}}
                                                        </td>
                                                        <td>
                                                            {{advert.period}}
                                                        </td>
                                                    </tr>

                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colspan="100%" class="text-right">
                                                            &nbsp;
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <button class="btn btn-primary float-right" v-on:click="claimDialog(item.id, item.dossier)">
                                                In behandeling nemen
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        </template>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="100%"></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <b-pagination-nav
                        v-model="currentPage"
                        :link-gen="pagination"
                        :number-of-pages="totalPages"
                        v-on:change="swapPage"
                        align="center"
                >
                </b-pagination-nav>
            </div>
        </div>
        <b-modal
                id="claim_modal"
                title="In behandeling nemen"
                @ok="makeClaim"
        >
            <p>
                U staat op het punt om het volgende dossier in behandeling te nemen:<br/>
                <strong>
                    {{claim.dossier}}
                </strong>
            </p>
            <p v-if="claim.combined.length > 0">
                Op het volgende adres: <br/>
                <strong>
                    {{claim.address}}
                </strong>
            </p>
            <p v-else>
                Voer een adres in (straat, huisnummer): <br/>
                <input type="text" v-model="claim.address" class="form-control">
            </p>
            <p>
                Weet u dit zeker?
            </p>
        </b-modal>


        <b-modal
                id="claim_error"
                title="Fout!"
        >
            <p>
                De door u gekozen advertentie is helaas niet meer beschikbaar.
            </p>
        </b-modal>
    </div>
</template>

<script>
    import {claimService, urgentsService} from "@/_services";
    import {settingsLabelService} from "@/_services/settings.labels.service";
    import {settingsProfilefieldService} from "@/_services/settings.profilefields.service";

    export default {
        name: 'UrgentenView',
        data: () => {
            return {
                currentPage: 1,
                totalPages: 1,
                items: [],
                claim: {
                    id: '',
                    dossier: '',
                    address: '',
                    address_manual: '',
                    address_id: '',
                    combined: ''
                },
                isVisible: [],
                filter: {
                    label: '',
                    regdate: 0,
                    status: '',
                    keyword: '',
                    lastname: '',
                    rooms: '',
                    ageFrom: '',
                    ageTo: ''
                },
                labels: [],
                profilefields: []
            }
        },
        methods: {
            async loadFilter() {
                settingsLabelService.getAll().then((data)=> {
                    this.labels = data;
                });
                settingsProfilefieldService.getAll().then((data)=> {
                    this.profilefields = data;
                });
            },
            claimDialog(id, dossier) {
                if(this.items[0].wmcorp) {
                    if(this.claim.combined.length){
                        this.claim.address = this.claim.combined.split('|')[1];
                        this.claim.address_id = this.claim.combined.split('|')[0];
                    } else {
                        return false;
                    }
                }

                this.claim.id = id;
                this.claim.dossier = dossier;
                this.$bvModal.show('claim_modal');
            },
            makeClaim() {
                claimService.make(this.claim.id, this.claim.address, this.claim.address_id).then((data)=>{
                    if(data === false) {
                        this.$bvModal.show('claim_error');
                    } else {
                        this.$router.push(`/claims/${this.claim.id}`);
                    }
                })
            },
            toggleVisibility(id) {
                const index = this.isVisible.indexOf(id);
                if (index > -1) {
                    this.isVisible.splice(index, 1)
                } else {
                    this.isVisible.push(id)
                }
            },
            async loadUrgents(offset) {
                urgentsService.getAll(
                    (offset-1)*10,
                    20,
                    this.filter.label,
                    this.filter.regdate,
                    this.filter.status,
                    this.filter.keyword,
                    this.filter.lastname,
                    this.filter.rooms,
                    this.filter.ageFrom,
                    this.filter.ageTo
                ).then((data) => {
                    this.items = data;
                    this.totalPages = Math.ceil(data[0].total / 10);
                })
            },
                formatDate(date) {
                if (!date) return 'Niet opgegeven';// Handle empty or invalid dates
                return new Intl.DateTimeFormat('nl-NL', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                }).format(new Date(date));
            },
            pagination() {
                return `#`;
            },
            async swapPage(val) {
                this.loadUrgents(val);
            },
            async doFilter() {
                this.currentPage = 1;
                this.loadUrgents(this.currentPage);
            },

            validateNumeric(field) {
                // Only allow numeric input for ageFrom and ageTo(Papy)
                if (field === 'ageFrom' || field === 'ageTo') {
                this.filter[field] = this.filter[field].replace(/[^0-9]/g, '');
                }

                  // Whenever ageFrom is updated (valid or invalid), clear ageTo
                if (field === 'ageFrom') {
                    const wasAgeToNotEmpty = this.filter.ageTo !== '';
                    this.filter.ageTo = ''; // Always clear ageTo when ageFrom changes
                     // If ageTo had a value, call doFilter after clearing
                    if (wasAgeToNotEmpty) {
                        this.doFilter();
                    }
                }

                // Call doFilter function if ageTo field is changed
                 if (field === 'ageTo') {
                this.doFilter();
                }
            }
      
        },
        
        computed: {
      isAgeToReadonly() {
        return !/\d/.test(this.filter.ageFrom); // If ageFrom has no number, ageTo is readonly(Papy)
      }
    },
        async mounted() {
            this.loadUrgents(this.currentPage);
            this.loadFilter();
        }
    }
</script>

<style scoped>

</style>
